export const actions = {
  GET_USER_LIST: 'GET_USER_LIST',
  GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_FAILED: 'GET_USER_LIST_FAILED',
  GET_STATUS: 'GET_STATUS',
  GET_STATUS_SUCCESS: 'GET_STATUS_SUCCESS',
  GET_STATUS_FAILED: 'GET_STATUS_FAILED',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  GET_LIST_USER_ROLES_REQUEST: 'GET_LIST_USER_ROLES_REQUEST',
  GET_LIST_USER_ROLES_SUCCESS: 'GET_LIST_USER_ROLES_SUCCESS',
  GET_LIST_USER_ROLES_FAILED: 'GET_LIST_USER_ROLES_FAILED',
  INVITE_USER: 'INVITE_USER',
  INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
  INVITE_USER_FAILED: 'INVITE_USER_FAILED',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
  UPDATE_USER_INFO_FAILED: 'UPDATE_USER_INFO_FAILED',
  RESEND_INVITATION: 'RESEND_INVITATION',
  RESEND_INVITATION_SUCCESS: 'RESEND_INVITATION_SUCCESS',
  RESEND_INVITATION_FAILED: 'RESEND_INVITATION_FAILED',
  RESEND_CONFIRM_EMAIL: 'RESEND_CONFIRM_EMAIL',
  RESEND_CONFIRM_EMAIL_SUCCESS: 'RESEND_CONFIRM_EMAIL_SUCCESS',
  RESEND_CONFIRM_EMAIL_FAILED: 'RESEND_CONFIRM_EMAIL_FAILED',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILED: 'DELETE_USER_FAILED',
  SUSPEND_USER: 'SUSPEND_USER',
  SUSPEND_USER_SUCCESS: 'SUSPEND_USER_SUCCESS',
  SUSPEND_USER_FAILED: 'SUSPEND_USER_FAILED',
  RESTORE_USER: 'RESTORE_USER',
  RESTORE_USER_SUCCESS: 'RESTORE_USER_SUCCESS',
  RESTORE_USER_FAILED: 'RESTORE_USER_FAILED',
  FORCE_RESET_PASSWORD: 'FORCE_RESET_PASSWORD',
  FORCE_RESET_PASSWORD_SUCCESS: 'FORCE_RESET_PASSWORD_SUCCESS',
  FORCE_RESET_PASSWORD_FAILED: 'FORCE_RESET_PASSWORD_FAILED',
  RESEND_RESET_PASSWORD: 'RESEND_RESET_PASSWORD',
  RESEND_RESET_PASSWORD_SUCCESS: 'RESEND_RESET_PASSWORD_SUCCESS',
  RESEND_RESET_PASSWORD_FAILED: 'RESEND_RESET_PASSWORD_FAILED'
};
