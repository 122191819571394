import { ofType } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { makeAjaxRequest, handleErrorsFromServer, PubSub } from '../../utils';
import { actions } from './constants';
import * as userActions from './actions';

const getUserListRolesEpic = action$ =>
  action$.pipe(
    ofType(actions.GET_LIST_USER_ROLES_REQUEST),
    mergeMap(action => {
      return makeAjaxRequest(
        'GET',
        `${process.env.REACT_APP_API_URL}/users/roles`
      ).pipe(
        map(data => {
          if (data.response.errors) {
            return userActions.getUserListRolesFailed(data.response.errors);
          } else {
            return userActions.getUserListRolesSuccess(data.response);
          }
        }),
        catchError(error => {
          handleErrorsFromServer(error);
          return of({
            type: actions.GET_USER_LIST_FAILED,
            payload: error.xhr.response,
            error: true
          });
        })
      );
    })
  );

const getUserListEpic = action$ =>
  action$.pipe(
    ofType(actions.GET_USER_LIST),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'GET',
        `${process.env.REACT_APP_API_URL}/admin/users`
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            return userActions.getUserListFailed(data.response.errors.message);
          }
          return userActions.getUserListSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          handleErrorsFromServer(error);
          return of({
            type: actions.GET_USER_LIST_FAILED,
            payload: error.xhr.response,
            error: true
          });
        })
      );
    })
  );
const getStatusEpic = action$ =>
  action$.pipe(
    ofType(actions.GET_STATUS),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'GET',
        `${process.env.REACT_APP_API_URL}/admin/status`
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            return userActions.getStatusFailed(data.response.errors.message);
          }
          return userActions.getStatusSuccess(data.response.data);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          handleErrorsFromServer(error);
          return of({
            type: actions.GET_STATUS_FAILED,
            payload: error.xhr.response,
            error: true
          });
        })
      );
    })
  );

const inviteUserEpic = action$ =>
  action$.pipe(
    ofType(actions.INVITE_USER),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');

      return makeAjaxRequest(
        'POST',
        `${process.env.REACT_APP_API_URL}/admin/invite`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            return userActions.inviteUserFailed(data.response.errors[0].msg);
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: `Invitation sent to ${data.response.data.email}`
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return userActions.inviteUserSuccess(data.response);
        }),
        catchError(errors => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(errors);
          if (action.payload.callback) {
            action.payload.callback(false, errorResponse);
          }
          return of({
            type: actions.INVITE_USER_FAILED,
            payload: errors.xhr.response,
            error: true
          });
        })
      );
    })
  );

const updateUserEpic = action$ =>
  action$.pipe(
    ofType(actions.UPDATE_USER_INFO),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'PUT',
        `${process.env.REACT_APP_API_URL}/users/update-user/${
          action.payload.userId
        }`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            const errorResponse = handleErrorsFromServer(data.response.errors);
            if (action.payload.callback) {
              action.payload.callback(false, errorResponse);
            }
            return userActions.updateUserInfoFailed(
              data.response.errors.message
            );
          }
          if (action.payload.isChangeEmail) {
            PubSub.publish('SHOW_NOTIFICATION', {
              msg: `Changes saved.`,
              title: 'Success'
            });
          } else if (action.payload.isChangeRole) {
            if (action.payload.isChangeCurrentUser) {
              PubSub.publish('SHOW_NOTIFICATION', {
                msg: `Your role has been changed to  ${
                  action.payload.params.newRoleName
                }. Now you have no privilege to perform this action.`,
                title: 'Role Changed'
              });
            } else {
              PubSub.publish('SHOW_NOTIFICATION', {
                msg: 'Changes saved.',
                title: 'Success'
              });
            }
          } else {
            PubSub.publish('SHOW_NOTIFICATION', {
              msg: 'Changes saved.',
              title: 'Success'
            });
          }

          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return userActions.updateUserInfoSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(error);
          if (action.payload.callback) {
            action.payload.callback(false, errorResponse);
          }
          return of(userActions.updateUserInfoFailed(errorResponse));
        })
      );
    })
  );

const resendInvitationEpic = action$ =>
  action$.pipe(
    ofType(actions.RESEND_INVITATION),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'POST',
        `${process.env.REACT_APP_API_URL}/admin/resend-invitation`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            return userActions.resendInvitationFailed(
              data.response.errors.message
            );
          }
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: 'Invitation sent.',
            title: 'Success'
          });
          return userActions.resendInvitationSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(error);
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: errorResponse.errors.message,
            title: 'Error',
            level: 'error'
          });
          return userActions.updateUserInfoFailed(errorResponse);
        })
      );
    })
  );

const resendConfirmEmailEpic = action$ =>
  action$.pipe(
    ofType(actions.RESEND_CONFIRM_EMAIL),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'POST',
        `${process.env.REACT_APP_API_URL}/admin/resend-verify-email`,
        action.payload
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            PubSub.publish('SHOW_NOTIFICATION', {
              msg: data.response.errors.message,
              title: 'Error',
              level: 'error'
            });
            return userActions.resendConfirmEmailFailed(
              data.response.errors.message
            );
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: 'Confirmation sent.',
            title: 'Success'
          });
          return userActions.resendConfirmEmailSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(error);
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: errorResponse.errors.message,
            title: 'Error',
            level: 'error'
          });
          return userActions.updateUserInfoFailed(errorResponse);
        })
      );
    })
  );

const deleteUserEpic = action$ =>
  action$.pipe(
    ofType(actions.DELETE_USER),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'DELETE',
        `${process.env.REACT_APP_API_URL}/admin/${
          action.payload.userId
        }/delete`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            return userActions.deleteUserFailed(data.response.errors.message);
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: 'Deleted user.',
            title: 'Delete Success'
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return userActions.deleteUserSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(error);
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: errorResponse.message,
            title: 'Error',
            level: 'error'
          });
          return userActions.deleteUserFailed(errorResponse);
        })
      );
    })
  );

const suspendUserEpic = action$ =>
  action$.pipe(
    ofType(actions.SUSPEND_USER),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'PUT',
        `${process.env.REACT_APP_API_URL}/admin/${
          action.payload.userId
        }/status`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            const errorResponse = handleErrorsFromServer(data.response.errors);
            if (action.payload.callback) {
              action.payload.callback(false, errorResponse);
            }
            return userActions.suspendUserFailed(data.response.errors.message);
          }

          PubSub.publish('SHOW_NOTIFICATION', {
            msg: 'User suspended.'
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return userActions.suspendUserSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(error);
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: errorResponse.message,
            title: 'Error',
            level: 'error'
          });
          return userActions.suspendUserFailed(errorResponse);
        })
      );
    })
  );

const restoreUserEpic = action$ =>
  action$.pipe(
    ofType(actions.RESTORE_USER),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'PUT',
        `${process.env.REACT_APP_API_URL}/admin/${
          action.payload.userId
        }/status`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            return userActions.restoreUserFailed(data.response.errors.message);
          }

          PubSub.publish('SHOW_NOTIFICATION', {
            msg: 'User restored.'
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return userActions.restoreUserSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(error);
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: errorResponse.errors.message,
            title: 'Error',
            level: 'error'
          });
          return userActions.restoreUserFailed(errorResponse);
        })
      );
    })
  );

const forceResetPasswordEpic = action$ =>
  action$.pipe(
    ofType(actions.FORCE_RESET_PASSWORD),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'POST',
        `${process.env.REACT_APP_API_URL}/admin/reset-password`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            return userActions.forceResetPasswordFailed(
              data.response.errors.message
            );
          }
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: action.payload.successMsg
              ? action.payload.successMsg
              : 'Email sent.'
          });
          return userActions.forceResetPasswordSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(error);
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: errorResponse.message,
            title: 'Error',
            level: 'error'
          });
          return userActions.forceResetPasswordFailed(errorResponse);
        })
      );
    })
  );

const resendResetPasswordEpic = action$ =>
  action$.pipe(
    ofType(actions.RESEND_RESET_PASSWORD),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'POST',
        `${process.env.REACT_APP_API_URL}/admin/resend-reset-password`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            return userActions.resendResetPasswordFailed(
              data.response.errors.message
            );
          }
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: 'Request sent.'
          });
          return userActions.resendResetPasswordSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(error);
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: errorResponse.errors.message,
            title: 'Error',
            level: 'error'
          });
          return userActions.resendResetPasswordFailed(errorResponse);
        })
      );
    })
  );

export default [
  getUserListEpic,
  inviteUserEpic,
  getUserListRolesEpic,
  updateUserEpic,
  getStatusEpic,
  resendInvitationEpic,
  resendConfirmEmailEpic,
  deleteUserEpic,
  restoreUserEpic,
  suspendUserEpic,
  forceResetPasswordEpic,
  resendResetPasswordEpic
];
