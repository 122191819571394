import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ContextMenu.scss';

class ContextMenu extends Component {
  static propTypes = {
    contextItem: PropTypes.any
  };
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      item: null,
      contextItem: this.props.contextItem,
      event: (e, item, contextItem, customRender) => {
        this.setState({ contextItem: contextItem });
        var event = e.nativeEvent;
        setTimeout(() => {
          this.setState({ item: item });
          this.setState({ visible: false });
          const clickY = event.pageY;
          const clickX = event.pageX;
          const rootW = this.root.offsetWidth;
          this.root.style.left = `${clickX - rootW - 275}px`;
          // this.root.style.right = `${window.innerWidth - event.clientX + 10}px`;
          this.root.style.top = `${clickY - 10}px`;
          if (event.pageX > 1024) {
            this.root.style.left = `${clickX - rootW - 275}px`;
          }

          if (event.pageX < 900 && event.pageX > 320) {
            this.root.style.left = `${clickX - rootW - 35}px`;
            this.root.style.top = `${clickY - 100}px`;
          }

          if (event.pageX < 320) {
            this.root.style.left = `${clickX - rootW - 30}px`;
            this.root.style.top = `${clickY - 100}px`;
          }
          // this.root.style.top = `${clickY - 10}px`;
          // event.pageX > 992 ? `${clickY - 10}px` : `${clickY - 160}px`;
          if (customRender) {
            customRender(this, clickX - rootW - 275, clickY - 10);
          }
        }, 10);
      }
    };
  }

  componentDidMount() {
    document.addEventListener('click', this._handleClick.bind(this));
    //document.addEventListener('contextmenu', this._handleContextMenu.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._handleClick.bind(this));
    //document.removeEventListener('contextmenu', this._handleContextMenu.bind(this));
  }

  _handleContextMenu(event) {
    event.preventDefault();

    let visible = !this.state.visible;

    this.setState({ visible: visible });

    if (!visible) {
      return;
    }

    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const rootW = this.root.offsetWidth;
    const rootH = this.root.offsetHeight;

    const right = screenW - clickX > rootW;
    const left = !right;
    const top = screenH - clickY > rootH;
    const bottom = !top;

    if (right) {
      this.root.style.left = `${clickX + 5}px`;
    }

    if (left) {
      this.root.style.left = `${clickX - rootW - 5}px`;
    }

    if (top) {
      this.root.style.top = `${clickY + 5}px`;
    }

    if (bottom) {
      this.root.style.top = `${clickY - rootH - 5}px`;
    }
  }

  _handleClick() {
    this.setState({ visible: true });
  }

  renderItem() {
    if (this.state.contextItem) {
      return this.state.contextItem.map((item, index) => {
        // let clsIcon = `${item.icon} cls-contextMenu-icon`;
        const className = [
          'contextMenu--option',
          item.separator ? 'separator' : '',
          item.info ? 'info' : '',
          item.disabled ? 'disabled' : ''
        ].join(' ');

        if (item.custom) {
          return (
            <div key={index} className={className + item.custom.className}>
              {item.custom.render}
            </div>
          );
        }

        return (
          <div
            className={className}
            onClick={e => {
              if (item.info) {
                return;
              }
              item.event(e, this.state.item);
            }}
          >
            {item.note ? (
              <div className="contextMenu-note" disabled>
                {item.note}
              </div>
            ) : null}
            <div key={index} className="contextMenu-title">
              {item.title}
              {/* dangerouslySetInnerHTML={{__html: item.title}} */}
            </div>
          </div>
        );
      });
    }
    return null;
  }

  render() {
    return (
      <div
        ref={ref => {
          this.root = ref;
        }}
        className={this.state.visible ? 'contextMenu-hidden' : 'contextMenu'}
      >
        {this.state.visible}
        {this.renderItem()}
      </div>
    );
  }
}

export default ContextMenu;
