import React from 'react';
import uniqueId from 'lodash/uniqueId';

const Input = locals => {
  const hasError = !!locals.error;

  const msg = locals.error ? (
    <div className="text-danger">{locals.error}</div>
  ) : (
    ''
  );

  const label = locals.label ? (
    <label className="control-label">
      {locals.label}{' '}
      {!locals.typeInfo.isMaybe &&
        locals.config &&
        !locals.config.ignoreRequiredLabel && (
          <span className="text-danger"> *</span>
        )}
    </label>
  ) : null;

  return (
    <div className={`form-group`}>
      {label}
      <input
        id={uniqueId()}
        className={`form-control ${hasError ? 'error' : ''}`}
        autoComplete="off"
        value={locals.value}
        onChange={event => {
          locals.attrs.type === 'password'
            ? locals.onChange(event.target.value.trim())
            : locals.onChange(event.target.value);
        }}
        {...locals.attrs}
      />
      {msg}
    </div>
  );
};

export default Input;
