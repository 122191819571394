import Loadable from 'react-loadable';
import { Indicators } from 'components';

const UserList = Loadable({
  loader: () => import('./containers/UserList'),
  loading: Indicators
});

export default [
  {
    path: '/users',
    component: UserList,
    private: true,
    roles: ['Tabula Admin', 'Tabula Super Admin'],
    menu: {
      name: 'Users',
      order: 2,
      icon: 'pe-7s-users'
    }
  }
];
