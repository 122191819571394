import React, { Component } from 'react';
//import Spinner from 'react-spinkit'; http://tobiasahlin.com/spinkit/
import './indicator.css';
import PropTypes from 'prop-types';

import PubSub from '../../utils/pubSubService';

class Indicators extends Component {
  static propTypes = {
    show: PropTypes.bool,
    id: PropTypes.string
  };

  state = {
    isLoading: false
  };

  componentDidMount() {
    PubSub.subscribe('SHOW_LOADING', () => {
      const { isLoading } = this.state;
      if (!isLoading) {
        this.setState({ isLoading: true });
      }
    });
    PubSub.subscribe('HIDE_LOADING', () => {
      this.setState({ isLoading: false });
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div id="indicator" className="cls-indicator">
          <div className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      );
    }
    return null;
  }
}

export default Indicators;
