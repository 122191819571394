import { actions } from './constants';
const initialState = {
  organizationList: {}
};

const OrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        organizationList: action.payload
      };
    default:
      return state;
  }
};

export default OrganizationReducer;
