import { actions } from './constants';

export const getUserListRolesRequest = () => ({
  type: actions.GET_LIST_USER_ROLES_REQUEST
});

export const getUserListRolesSuccess = payload => ({
  type: actions.GET_LIST_USER_ROLES_SUCCESS,
  payload
});

export const getUserListRolesFailed = error => ({
  type: actions.GET_LIST_USER_ROLES_FAILED
});

export const getUserList = () => ({
  type: actions.GET_USER_LIST
});

export const getUserListSuccess = payload => ({
  type: actions.GET_USER_LIST_SUCCESS,
  payload
});

export const getUserListFailed = error => ({
  type: actions.GET_USER_LIST_FAILED,
  payload: {
    error
  }
});
export const getStatus = () => ({
  type: actions.GET_STATUS
});

export const getStatusSuccess = payload => ({
  type: actions.GET_STATUS_SUCCESS,
  payload
});

export const getStatusFailed = error => ({
  type: actions.GET_STATUS_FAILED,
  payload: {
    error
  }
});

export const inviteUser = payload => ({
  type: actions.INVITE_USER,
  payload
});

export const inviteUserSuccess = payload => ({
  type: actions.INVITE_USER_SUCCESS,
  payload
});

export const inviteUserFailed = error => ({
  type: actions.INVITE_USER_FAILED,
  payload: {
    error
  }
});

export const resendInvitation = payload => ({
  type: actions.RESEND_INVITATION,
  payload
});

export const resendInvitationSuccess = payload => ({
  type: actions.RESEND_INVITATION_SUCCESS,
  payload
});

export const resendInvitationFailed = error => ({
  type: actions.RESEND_INVITATION_FAILED,
  payload: {
    error
  }
});

export const resendConfirmEmail = payload => ({
  type: actions.RESEND_CONFIRM_EMAIL,
  payload
});

export const resendConfirmEmailSuccess = payload => ({
  type: actions.RESEND_CONFIRM_EMAIL_SUCCESS,
  payload
});

export const resendConfirmEmailFailed = error => ({
  type: actions.RESEND_CONFIRM_EMAIL_FAILED,
  payload: {
    error
  }
});

export const updateUserInfo = payload => ({
  type: actions.UPDATE_USER_INFO,
  payload
});

export const updateUserInfoSuccess = payload => ({
  type: actions.UPDATE_USER_INFO_SUCCESS,
  payload
});

export const updateUserInfoFailed = error => ({
  type: actions.UPDATE_USER_INFO_FAILED,
  payload: {
    error
  }
});

export const deleteUser = payload => ({
  type: actions.DELETE_USER,
  payload
});

export const deleteUserSuccess = payload => ({
  type: actions.DELETE_USER_SUCCESS,
  payload
});

export const deleteUserFailed = error => ({
  type: actions.DELETE_USER_FAILED,
  payload: {
    error
  }
});

export const suspendUser = payload => ({
  type: actions.SUSPEND_USER,
  payload
});

export const suspendUserSuccess = payload => ({
  type: actions.SUSPEND_USER_SUCCESS,
  payload
});

export const suspendUserFailed = error => ({
  type: actions.SUSPEND_USER_FAILED,
  payload: {
    error
  }
});
export const restoreUser = payload => ({
  type: actions.RESTORE_USER,
  payload
});

export const restoreUserSuccess = payload => ({
  type: actions.RESTORE_USER_SUCCESS,
  payload
});

export const restoreUserFailed = error => ({
  type: actions.RESTORE_USER_FAILED,
  payload: {
    error
  }
});
export const forceResetPassword = payload => ({
  type: actions.FORCE_RESET_PASSWORD,
  payload
});

export const forceResetPasswordSuccess = payload => ({
  type: actions.FORCE_RESET_PASSWORD_SUCCESS,
  payload
});

export const forceResetPasswordFailed = error => ({
  type: actions.FORCE_RESET_PASSWORD_FAILED,
  payload: {
    error
  }
});
export const resendResetPassword = payload => ({
  type: actions.RESEND_RESET_PASSWORD,
  payload
});

export const resendResetPasswordSuccess = payload => ({
  type: actions.RESEND_RESET_PASSWORD_SUCCESS,
  payload
});

export const resendResetPasswordFailed = error => ({
  type: actions.RESEND_RESET_PASSWORD_FAILED,
  payload: {
    error
  }
});
