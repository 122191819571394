import { actions } from './constants';
const initialState = {
  userList: {},
  userListRoles: [],
  status: {}
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_LIST_USER_ROLES_SUCCESS:
      return {
        ...state,
        userListRoles: action.payload.data
      };
    case actions.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload
      };
    case actions.GET_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload
      };
    default:
      return state;
  }
};

export default UserReducer;
