import Loadable from 'react-loadable';
import { Indicators } from 'components';
import Logout from './containers/Logout';

const Login = Loadable({
  loader: () => import('./containers/LoginPage'),
  loading: Indicators
});

const AcceptInvitation = Loadable({
  loader: () => import('./containers/AcceptInvitation'),
  loading: Indicators
});

const ConfirmEmail = Loadable({
  loader: () => import('./containers/ConfirmEmail'),
  loading: Indicators
});

const ForgotPassword = Loadable({
  loader: () => import('./containers/ForgotPassword'),
  loading: Indicators
});

const SetPassword = Loadable({
  loader: () => import('./containers/SetPassword'),
  loading: Indicators
});

export default [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/logout',
    component: Logout
  },
  {
    path: '/setup-account/:token',
    component: AcceptInvitation
  },
  {
    path: '/users/confirm-email/:token',
    component: ConfirmEmail
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    path: '/set-password/:token',
    component: SetPassword
  }
];
