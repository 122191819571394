import PubSub from './pubSubService';

const defaultError = 'Something went wrong. Please try again later';
function handleErrorsFromServer(errors) {
  switch (errors.status) {
    case 401:
      localStorage.removeItem('access_token');
      window.location.href = `${window.location.origin}/login`;
      break;
    case 404:
      // window.location.href = `${window.location.origin}/404`;
      return errors.response;
    case 400:
    case 422:
      return errors.response;
    case 403:
      // TODO 403
      localStorage.removeItem('access_token');
      window.location.href = `${window.location.origin}/forbidden`;
      break;
    case 500:
      PubSub.publish('SHOW_NOTIFICATION', {
        msg: defaultError,
        level: 'error'
      });
      return {
        message: defaultError
      };
    default:
      PubSub.publish('SHOW_NOTIFICATION', {
        msg: defaultError,
        level: 'error'
      });
      return {
        message: defaultError
      };
  }
}

export default handleErrorsFromServer;
