import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

export class UserCard extends Component {
  render() {
    return (
      <div className="card card-user">
        <div className="image">
          <img src={this.props.bgImage} alt="..." />
        </div>
        <div className="content">
          <div className="author">
            <a href="#pablo">
              <img
                className="avatar border-gray"
                src={this.props.avatar}
                alt="..."
              />
              <h4 className="title">
                {this.props.name}
                <br />
                <small>{this.props.userName}</small>
              </h4>
            </a>
          </div>
          <p className="description text-center">{this.props.description}</p>
        </div>
        <hr />
        <div className="text-center">{this.props.socials}</div>
      </div>
    );
  }
}

UserCard.propTypes = {
  userName: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  socials: PropTypes.string,
  bgImage: PropTypes.string,
  avatar: PropTypes.string
};
export default UserCard;
