import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Indicators, Notifications } from 'components';

class PublicRoute extends React.Component {
  render() {
    const { component: Component, t, ...rest } = this.props;
    return [
      <Route
        {...rest}
        // t={t}
        key="route"
        render={props => {
          return (
            <div className="wrapper wrapper-full-page wrapper-outsite-page wrapper-template-page">
              <div className="wrapper-content">
                <Indicators />
                <Notifications />
                <Component {...props} {...rest} />
              </div>
            </div>
          );
        }}
      />
    ];
  }
}
PublicRoute.propTypes = {
  location: PropTypes.any,
  component: PropTypes.any,
  authed: PropTypes.bool,
  external: PropTypes.bool,
  t: PropTypes.func
};
export default PublicRoute;
