import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class InputEditAble extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    inputName: PropTypes.string,
    errorMsg: PropTypes.string,
    className: PropTypes.any,
    defaultValue: PropTypes.string,
    buttonSize: PropTypes.string,
    ignoreRequired: PropTypes.bool,
    isCapitalize: PropTypes.bool,
    placeholder: PropTypes.string,
    onCancel: PropTypes.func,
    autoFocus: PropTypes.bool,
    actionButtonPosition: PropTypes.string,
    ignoreOnBlur: PropTypes.bool,
    style: PropTypes.object,
    validEmail: PropTypes.bool,
    maxLength: PropTypes.string
  };

  static defaultProps = {
    className: '',
    defaultValue: '',
    inputName: 'input-edit-able',
    ignoreRequired: false,
    placeholder: '',
    actionButtonPosition: 'bottom',
    validEmail: false,
    maxLength: '40'
  };

  constructor(props) {
    super(props);
    this.state = {
      inputEditAble: false,
      inputValue: {},
      errorMsg: props.errorMsg
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      errorMsg: nextProps.errorMsg
    });
  }

  onSubmit = () => {
    const { inputName, defaultValue } = this.props;

    const { errorMsg, inputValue } = this.state;

    if (errorMsg) {
      // console.log('error');
    } else {
      const value = inputValue[inputName]
        ? inputValue[inputName].trim()
        : defaultValue;

      this.props.onSubmit(value, { callback: this.onCancel });
    }
  };

  onCancel = () => {
    this.setState(
      {
        inputEditAble: false,
        errorMsg: null
      },
      () => {
        if (this.props.onCancel) {
          this.props.onCancel();
        }
      }
    );
  };

  validatorEmail = emailString => {
    const emailPattern = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+([a-zA-Z0-9]{2,})$/;

    if (!emailPattern.test(emailString.trim())) {
      return false;
    }

    return true;
  };

  onChangeInput = e => {
    e.preventDefault();

    const { validEmail } = this.props;

    const { value } = e.target;

    const valueTrim = value.trim();

    if (e.target && valueTrim) {
      if (validEmail) {
        if (this.validatorEmail(value)) {
          this.setState({
            inputValue: {
              [this.props.inputName]: value
            },
            errorMsg: null
          });
        } else {
          this.setState({
            inputValue: {
              [this.props.inputName]: value
            },
            errorMsg: 'Please enter a valid email address.'
          });
        }
      } else {
        this.setState({
          inputValue: {
            [this.props.inputName]: value
          },
          errorMsg: null
        });
      }
    } else {
      if (!this.props.ignoreRequired) {
        this.setState({
          inputValue: {
            [this.props.inputName]: value
          },
          errorMsg: this.props.errorMsg || 'This information is required.'
        });
      } else {
        this.setState({
          inputValue: {
            [this.props.inputName]: value
          }
        });
      }
    }
  };

  render() {
    const { inputEditAble, errorMsg, inputValue } = this.state;
    const {
      defaultValue,
      inputName,
      className,
      placeholder,
      maxLength,
      autoFocus
    } = this.props;
    let value = inputValue[inputName];
    if (inputEditAble) {
      return (
        <>
          <div className="wrapper-input-edit-able">
            <input
              type="text"
              key={inputName}
              className={`form-control ${className}`}
              value={value}
              defaultValue={defaultValue}
              placeholder={placeholder}
              maxLength={maxLength}
              onChange={this.onChangeInput}
              name={inputName}
              autoFocus={autoFocus ? true : false}
              style={errorMsg ? { borderColor: 'red' } : {}}
            />
            <button
              key="cancel"
              className="input-edit-able input-edit-able-cancel input-over-btn"
              onClick={this.onCancel}
            >
              <i className={'fa fa-close'} aria-hidden="true" />
            </button>
            <button
              key="save"
              className="input-edit-able input-edit-able-save input-over-btn"
              onClick={this.onSubmit}
            >
              <i className={'fa fa-check'} aria-hidden="true" />
            </button>
          </div>

          {this.state.errorMsg && (
            <span className="text-danger help-block">
              {this.state.errorMsg}
            </span>
          )}
        </>
      );
    }
    return (
      <div className="wrapper-input-edit-able">
        {defaultValue}{' '}
        <a
          className="tb-margin-left-10 edit-button"
          onClick={() => this.setState({ inputEditAble: true })}
        >
          <i className="fa fa-edit" />
        </a>
      </div>
    );
  }
}

export default InputEditAble;
