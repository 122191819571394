import React from 'react';
import Select from 'react-select';
import filter from 'lodash/filter';
import './Select.scss';

const SelectComponent = locals => {
  const hasError = !!locals.error;

  // const hasError = locals.hasError === true ? 'error' : '';
  const msg = locals.error ? (
    <div className="text-danger">{locals.error}</div>
  ) : (
    ''
  );

  const label = locals.label ? (
    <label className="control-label">
      {locals.label}{' '}
      {!locals.typeInfo.isMaybe && !locals.ignoreRequiredLabel && (
        <span className="text-danger"> *</span>
      )}
    </label>
  ) : null;

  const valueKey = locals.config.valueKey ? locals.config.valueKey : 'value';
  return (
    <div className={`form-group`}>
      <span>{label}</span>
      <Select
        value={
          filter(
            locals.config.options,
            item => item[valueKey].toString() === locals.value.toString()
          )[0]
        }
        onChange={selectedOption => locals.onChange(selectedOption[valueKey])}
        options={locals.config.options || []}
        {...locals.attrs}
        className={`form-select ${hasError ? 'error' : ''}`}
      />
      {msg}
    </div>
  );
};

export default SelectComponent;
