import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../actions';

class Logout extends React.Component {
  state = { isBusy: this.props.isBusy };

  componentDidMount() {
    this.props.logout();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isBusy !== state.isBusy) {
      return {
        isBusy: props.isBusy
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (!this.state.isBusy) {
      this.props.history.push('/login');
    }
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  t: PropTypes.func,
  logout: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  isBusy: PropTypes.bool
};
const mapStateToProps = state => ({
  isBusy: state.AuthReducer.isBusy
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
