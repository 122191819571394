export const actions = {
  REQUEST_LOGIN: 'REQUEST_LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_REJECT: 'LOGIN_REJECT',
  REQUEST_SET_UP_ACCOUNT: 'REQUEST_SET_UP_ACCOUNT',
  SET_UP_ACCOUNT_SUCCESS: 'SET_UP_ACCOUNT_SUCCESS',
  SET_UP_ACCOUNT_REJECT: 'SET_UP_ACCOUNT_REJECT',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILED: 'GET_CURRENT_USER_FAILED',
  CHECK_TOKEN: 'CHECK_TOKEN',
  CHECK_TOKEN_FAILED: 'CHECK_TOKEN_FAILED',
  CHECK_TOKEN_SUCCESS: 'CHECK_TOKEN_SUCCESS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED',
  REQUEST_FORGOT_PASSWORD: 'REQUEST_FORGOT_PASSWORD',
  REQUEST_FORGOT_PASSWORD_SUCCESS: 'REQUEST_FORGOT_PASSWORD_SUCCESS',
  REQUEST_FORGOT_PASSWORD_FAILED: 'REQUEST_FORGOT_PASSWORD_FAILED',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_FAILED: 'SET_PASSWORD_FAILED'
};
