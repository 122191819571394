import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import trim from 'lodash/trim';

function checkEmail(email) {
  const formatEmail = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+([a-zA-Z0-9]{2,})$/;
  const emailAfterTrim = trim(email);
  if (formatEmail.test(emailAfterTrim)) {
    const theLastIndexOfAtSign = emailAfterTrim.lastIndexOf('@');
    if (emailAfterTrim.slice(0, theLastIndexOfAtSign).length > 64) {
      return false;
    }
    return true;
  }
  return false;
}

function checkPassword(password) {
  // const formatPass = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d/!/@/#/$/%/^/&/*/(/)/=/_/+/?]{6,}$/
  // return formatPass.test(password)
  return password.length >= 9 && password.length <= 40; // password.length >= 8 &&
}

function checkConfirmPassword(password, confirmPassword) {
  return password === confirmPassword;
}

function differenceObject(object, base) {
  function changes(object, base) {
    return transform(object, function(result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export { checkEmail, checkPassword, checkConfirmPassword, differenceObject };
