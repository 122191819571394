// import jwtDecode from 'jwt-decode';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { makeAjaxRequest, PubSub, handleErrorsFromServer } from '../../utils';

import { actions } from './constants';
import * as sessionActions from './actions';

const loginEpic = action$ =>
  action$.pipe(
    ofType(actions.REQUEST_LOGIN),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return ajax
        .post(
          `${process.env.REACT_APP_API_URL}/users/session`,
          {
            identity: action.payload.identity,
            password: action.payload.password,
            remember: action.payload.rememberMe
          },
          { 'Content-Type': 'application/json' }
        )

        .pipe(
          map(data => {
            PubSub.publish('HIDE_LOADING');
            if (data.response.errors) {
              if (action.payload.callback) {
                action.payload.callback(false, data.response);
              }
              return sessionActions.loginReject(data.response.errors.message);
            } else {
              localStorage.setItem('access_token', data.response.token);
              if (action.payload.callback) {
                action.payload.callback(true);
              }
              return sessionActions.loginSuccess({
                token: data.response.token,
                rememberMe: action.payload.rememberMe,
                expires: data.response.exp
              });
            }
          }),
          catchError(error => {
            PubSub.publish('HIDE_LOADING');
            const errorResponse = handleErrorsFromServer(error);
            if (action.payload.callback) {
              action.payload.callback(false, errorResponse);
            }
            return of(sessionActions.loginReject(errorResponse.errors));
          })
        );
    })
  );

const logoutEpic = action$ =>
  action$.pipe(
    ofType(actions.LOGOUT),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'DELETE',
        `${process.env.REACT_APP_API_URL}/users/session`
      ).pipe(
        map(() => {
          localStorage.removeItem('access_token');
          // window.location.href = `${window.location.origin}/login`;
          PubSub.publish('HIDE_LOADING');
          return { type: 'LOGOUT_SUCCESS' };
        }),
        catchError(() => {
          // PubSub.publish('HIDE_LOADING');
          localStorage.removeItem('access_token');
          // window.location.href = `${window.location.origin}/login`;
          return { type: 'LOGOUT_SUCCESS' };
        })
      );
    })
  );

const setUpAccountEpic = action$ =>
  action$.pipe(
    ofType(actions.REQUEST_SET_UP_ACCOUNT),
    mergeMap(action =>
      ajax
        .post(
          `${process.env.REACT_APP_API_URL}/users/confirm-invite-email`,
          {
            token: action.payload.token,
            password: action.payload.password
          },
          { 'Content-Type': 'application/json' }
        )

        .pipe(
          map(data => {
            if (data.response.errors) {
              if (action.payload.callback) {
                action.payload.callback(false, data.response.errors);
              }
              return sessionActions.setUpAccountReject(
                data.response.errors.message
              );
            } else {
              if (action.payload.callback) {
                action.payload.callback(true);
              }
              return sessionActions.setUpAccountSuccess(data.response.email);
            }
          }),
          catchError(error => {
            return of({
              type: sessionActions.setUpAccountReject,
              payload: error.xhr.response,
              error: true
            });
          })
        )
    )
  );

const confirmEmailEpic = action$ =>
  action$.pipe(
    ofType(actions.CONFIRM_EMAIL),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'PUT',
        `${process.env.REACT_APP_API_URL}/users/confirm-email/${
          action.payload.token
        }`
      ).pipe(
        map(data => {
          if (data.response.errors) {
            if (action.payload.callback) {
              action.payload.callback(false, data.response.errors);
              PubSub.publish('HIDE_LOADING');
            }
          } else {
            if (action.payload.callback) {
              action.payload.callback(true);
              PubSub.publish('HIDE_LOADING');
            }
          }
          return { type: 'NOT_THING' };
        }),
        catchError(error => {
          const errorResponse = handleErrorsFromServer(error);
          PubSub.publish('HIDE_LOADING');
          action.payload.callback(false, errorResponse);
          return { type: 'NOT_THING' };
        })
      );
    })
  );

const getCurrentUserEpic = action$ =>
  action$.pipe(
    ofType(actions.GET_CURRENT_USER),
    mergeMap(action => {
      return makeAjaxRequest(
        'GET',
        `${process.env.REACT_APP_API_URL}/users/me`
      ).pipe(
        map(data => {
          if (data.response.errors) {
            localStorage.removeItem('access_token');
            return sessionActions.getCurrentUserFailed(
              data.response.errors.message
            );
          }
          if (action.payload && action.payload.callback) {
            action.payload.callback(true);
          }
          return sessionActions.getCurrentUserSuccess(data.response.user);
        }),
        catchError(errors => {
          const errorResponse = handleErrorsFromServer(errors);
          return of(sessionActions.getCurrentUserFailed(errorResponse));
        })
      );
    })
  );

const checkTokenEpic = action$ =>
  action$.pipe(
    ofType(actions.CHECK_TOKEN),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');

      return makeAjaxRequest(
        'GET',
        `${process.env.REACT_APP_API_URL}/check-token?token=${
          action.payload.token
        }&type=${action.payload.type}`
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');

          if (data.response.errors) {
            return sessionActions.checkTokenFailed(
              data.response.errors.message
            );
          }
          if (action.payload && action.payload.callback) {
            action.payload.callback(true);
          }
          return sessionActions.checkTokenSuccess(data.response.token);
        }),
        catchError(errors => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(errors);
          return of(sessionActions.checkTokenFailed(errorResponse));
        })
      );
    })
  );

const setPasswordEpic = action$ =>
  action$.pipe(
    ofType(actions.SET_PASSWORD),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return ajax
        .post(
          `${process.env.REACT_APP_API_URL}/users/set-password/?token=${
            action.payload.params.token
          }`,
          { password: action.payload.params.password },
          { 'Content-Type': 'application/json' }
        )

        .pipe(
          map(data => {
            if (data.response.errors) {
              if (action.payload.callback) {
                action.payload.callback(false, data.response.errors);
              }
              return sessionActions.setPasswordFailed(
                data.response.errors.message
              );
            } else {
              PubSub.publish('SHOW_NOTIFICATION', {
                msg: `You have successfully reset your password.`
              });
              setTimeout(() => {
                PubSub.publish('HIDE_LOADING');

                if (action.payload.callback) {
                  action.payload.callback(true);
                }
              }, 1500);

              return sessionActions.setPasswordSuccess(data.response);
            }
          }),
          catchError(error => {
            PubSub.publish('HIDE_LOADING');
            if (action.payload.callback) {
              action.payload.callback(false, error);
            }
            return of({
              type: sessionActions.setPasswordFailed,
              payload: error.xhr.response,
              error: true
            });
          })
        );
    })
  );

const requestForgotPasswordEpic = action$ =>
  action$.pipe(
    ofType(actions.REQUEST_FORGOT_PASSWORD),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'POST',
        `${process.env.REACT_APP_API_URL}/users/forgot-password`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            if (action.payload.callback) {
              action.payload.callback(false, data.response.errors);
            }
            return sessionActions.requestForgotPasswordFailed(
              data.response.errors.message
            );
          } else {
            if (action.payload.callback) {
              action.payload.callback(true);
            }
            return sessionActions.requestForgotPasswordSuccess(
              data.response.email
            );
          }
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          if (action.payload.callback) {
            action.payload.callback(false, error);
          }
          return of({
            type: sessionActions.requestForgotPasswordFailed,
            payload: error.xhr.response,
            error: true
          });
        })
      );
    })
  );

export default [
  loginEpic,
  setUpAccountEpic,
  getCurrentUserEpic,
  checkTokenEpic,
  confirmEmailEpic,
  setPasswordEpic,
  requestForgotPasswordEpic,
  logoutEpic
];
