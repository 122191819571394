import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import map from 'lodash/map';

const BreadCrumb = props => {
  const { breadcrumbs, title } = props;
  return (
    <div className="breadcrumb-wrapper">
      {/* <div className="breadcrumb-title">{title}</div> */}
      <ol role="navigation" aria-label="breadcrumbs" class="breadcrumb">
        <li>
          <Link to="/" role="button">
            Home
          </Link>
        </li>
        {breadcrumbs &&
          map(breadcrumbs, (item, index) => {
            if (item.currentPage) {
              return (
                <li class="active">
                  <span>{item.label}</span>
                </li>
              );
            }
            return (
              <li class="">
                <Link to={item.url} role="button">
                  {item.label}
                </Link>
              </li>
            );
          })}
      </ol>
    </div>
  );
};

BreadCrumb.defaultProps = {
  breadcrumbs: []
};

BreadCrumb.propTypes = {
  breadcrumbs: PropTypes.array,
  title: PropTypes.string
};

export default BreadCrumb;
