import React from 'react';
import { PropTypes } from 'prop-types';

const CustomRadio = props => {
  const options = props.attrs.options;
  const msg = props.error ? (
    <div className="text-danger">{props.error}</div>
  ) : (
    ''
  );

  const label = props.label ? (
    <label className="control-label">{props.label}</label>
  ) : null;

  return (
    <div>
      {label}
      {options.map(option => (
        <div className="radio" onClick={() => props.onChange(option.value)}>
          <input
            checked={props.value === option.value}
            type="radio"
            value={option.value}
          />
          <label>{option.text}</label>
          {msg}
        </div>
      ))}
    </div>
  );
};

CustomRadio.propTypes = {
  attrs: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object
};

export default CustomRadio;

// const options = {
//   auto: 'placeholders',
//   fields: {
//     radio1: {
//       label: 'Radio',
//       attrs: {
//         type: 'radio',
//         options: [
//           { text: 'radio 1', value: '1' },
//           { text: 'radio 2', value: '2' },
//           { text: 'radio 3', value: '3' }
//         ],
//         value: '3'
//       },
//       template: UIComponent.Radio
//     }
//   }
// };
