import Loadable from 'react-loadable';
import { Indicators } from 'components';

const RolePermissionList = Loadable({
  loader: () => import('./containers/RolePermissionList'),
  loading: Indicators
});

export default [
  {
    path: '/roles-permissions',
    component: RolePermissionList,
    private: true,
    roles: ['Tabula Admin', 'Tabula Super Admin'],
    menu: {
      name: 'Roles & Permissions',
      order: 4,
      icon: 'pe-7s-lock'
    }
  }
];
