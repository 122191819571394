import React from 'react';
import { Button } from 'react-bootstrap';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './CustomButton.scss';
class CustomButton extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     currentBgColor: props.background
  //   };
  // }
  // changeBgColor = () =>
  //   this.setState({ currentBgColor: this.props.background });
  render() {
    const { children, width, height, btnForm, ...props } = this.props;
    return (
      <div className={`tabula-button-wrapper ${this.props.btnclass || ''}`}>
        <Button
          {...props}
          // htmlType="submit"
          // onClick={this.props.onClick}
          style={{ height: height, width: width }}
          className={`tabula-button ${btnForm}-${this.props.background}-${
            this.props.color
          }`}
        >
          {children}
        </Button>
      </div>
    );
  }
}
CustomButton.propTypes = {
  background: PropTypes.string,
  form: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.number,
  color: PropTypes.string,
  children: PropTypes.any
};
export default CustomButton;
