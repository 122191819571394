import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navbar, NavDropdown, Nav, MenuItem } from 'react-bootstrap';

import { logout } from '../../modules/Auth/actions';

class Header extends Component {
  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.object,
    dispatch: PropTypes.func,
    currentUser: PropTypes.object,
    logout: PropTypes.func
  };

  // function that makes the sidebar from normal to mini and vice-versa
  handleMinimizeSidebar() {
    document.body.classList.toggle('sidebar-mini');
  }
  // function for responsive that hides/shows the sidebar
  mobileSidebarToggle() {
    document.documentElement.classList.toggle('nav-open');
  }

  checkCollapsedSidebar = () => {
    return document.documentElement.classList.contains('nav-open');
  };

  onLogout = () => {
    // const { logout } = this.props;
    // logout();
    if (this.checkCollapsedSidebar()) {
      this.mobileSidebarToggle();
    }
    this.props.history.push('/logout');
  };

  render() {
    const { history, currentUser } = this.props;
    return [
      <Navbar fluid key="navbar">
        <div className="navbar-minimize">
          <button
            id="minimizeSidebar"
            className="btn btn-default btn-fill btn-round btn-icon"
            onClick={this.handleMinimizeSidebar}
          >
            <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
            <i className="fa fa-navicon visible-on-sidebar-mini" />
          </button>
        </div>
        <Navbar.Header>
          {/* <Navbar.Brand>
            <div
              className="inner-navbar-brand logo-header"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/');
              }}
            >
              {this.renderLogo()}
            </div>
          </Navbar.Brand> */}
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        {/* <Navbar.Form pullLeft className="navbar-search-form" /> */}
        <Nav pullRight style={{ marginRight: 15 }}>
          <div>
            <Nav pullRight>
              <NavDropdown
                eventKey={4}
                title={
                  <span className="profile">
                    {currentUser && currentUser.firstName
                      ? `${currentUser.firstName} ${currentUser.lastName}`
                      : ''}
                  </span>
                }
                noCaret
                id="basic-nav-dropdown-3"
                bsClass="dropdown-with-icons dropdown"
                className="dropdown-profile"
              >
                <MenuItem
                  eventKey={4.4}
                  onClick={() => history.push('/my-profile')}
                >
                  <i className="pe-7s-user" /> My Account
                </MenuItem>
                <MenuItem
                  eventKey={4.5}
                  onClick={() => {
                    this.onLogout();
                  }}
                >
                  <div className="text-danger">
                    <i className="pe-7s-close-circle" /> Log out
                  </div>
                </MenuItem>
              </NavDropdown>
            </Nav>
          </div>
        </Nav>
      </Navbar>
    ];
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});
// prettier-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
