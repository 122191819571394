import React, { PureComponent } from 'react';
import { Row, Col, Grid } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { PropTypes } from 'prop-types';
import './Error.scss';

class Forbidden extends PureComponent {
  render() {
    const { t } = this.props;

    return (
      <Row center="xs">
        <Col lg={12} className="error-page">
          <div className="number">403</div>
          <div className="short-description">{t('forbidden')}</div>
          <div className="detail-description">{t('no_permission')}</div>
        </Col>
      </Row>
    );
  }
}

Forbidden.propTypes = {
  t: PropTypes.func
};

export default withNamespaces(['error'])(Forbidden);
