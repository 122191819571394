import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import { UIComponent } from 'components';

import './ConfirmModal.scss';

class ConfirmModal extends React.Component {
  state = {};

  onCancelSweetAlert = () => {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
    this.hideConfirm();
  };

  onSubmitSweetAlert = () => {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit();
    this.hideConfirm();
  };

  hideConfirm = () => {
    this.setState({ isShowConfirm: false });
  };

  showConfirm = () => {
    this.setState({ isShowConfirm: true });
  };

  render() {
    const { message, title, confirmBtnText, cancelBtnText, t } = this.props;

    const { isShowConfirm } = this.state;

    if (isShowConfirm) {
      return (
        <Modal
          backdrop="static"
          show={true}
          bsSize="md"
          onHide={this.hideConfirm}
          className="confirm-modal"
        >
          <Row>
            <Col md={12} sm={12}>
              <Modal.Header closeButton>
                <Modal.Title className="confirm-title">{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="content">{message}</div>
                <Row className="button-row tb-mt-10">
                  <UIComponent.Button
                    className="btn-not-fill"
                    bsStyle="default"
                    not-fill
                    onClick={this.hideConfirm}
                  >
                    {cancelBtnText}
                  </UIComponent.Button>
                  <UIComponent.Button
                    bsStyle="default"
                    fill
                    onClick={this.onSubmitSweetAlert}
                  >
                    {confirmBtnText}
                  </UIComponent.Button>
                </Row>
              </Modal.Body>
            </Col>
          </Row>
        </Modal>
      );
    }

    return null;
  }
}

ConfirmModal.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  confirmBtnBsStyle: PropTypes.string,
  cancelBtnBsStyle: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  showCancel: PropTypes.bool,
  confirmBtnCssClass: PropTypes.string
};

ConfirmModal.defaultProps = {
  title: 'Confirmation'
};

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(ConfirmModal);
