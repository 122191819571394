import React, { Component } from 'react';
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactTable from 'react-table';
import { PropTypes } from 'prop-types';

import './CustomTable.scss';

class CustomTable extends Component {
  render() {
    const { ...props } = this.props;

    return (
      <ReactTable
        {...props}
        getTrProps={this.props.getTrProps}
        data={this.props.data}
        columns={this.props.columns}
        minRows={0}
        showPagination={false}
        className={`-striped -highlight table-list-wrapper `}
        defaultPageSize={1000}
      />
    );
  }
}

CustomTable.propTypes = {
  getTrProps: PropTypes.func,
  data: PropTypes.array,
  columns: PropTypes.array
};
export default CustomTable;
