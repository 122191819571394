import { ofType } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { makeAjaxRequest, handleErrorsFromServer, PubSub } from '../../utils';
import { actions } from './constants';
import * as organizationActions from './actions';

const getOrganizationListEpic = action$ =>
  action$.pipe(
    ofType(actions.GET_ORGANIZATION_LIST),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'GET',
        `${process.env.REACT_APP_API_URL}/organizations`
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            return organizationActions.getOrganizationFailed(
              data.response.errors.message
            );
          }
          return organizationActions.getOrganizationSuccess(data.response);
        }),
        catchError(error => {
          PubSub.publish('HIDE_LOADING');
          handleErrorsFromServer(error);
          return of({
            type: actions.GET_ORGANIZATION_LIST_FAILED,
            payload: error.xhr.response,
            error: true
          });
        })
      );
    })
  );

const inviteOrganizationEpic = action$ =>
  action$.pipe(
    ofType(actions.INVITE_ORGANIZATION),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');

      return makeAjaxRequest(
        'POST',
        `${process.env.REACT_APP_API_URL}/organization/invite`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            if (action.payload.callback) {
              action.payload.callback(false, data.response);
            }
            return organizationActions.inviteOrganizationFailed(
              data.response.errors
            );
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: `Invitation sent to ${action.payload.params.owner.email}`
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return organizationActions.inviteOrganizationSuccess(data.response);
        }),
        catchError(errors => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(errors);
          if (action.payload.callback) {
            action.payload.callback(false, errorResponse);
          }
          return of({
            type: actions.INVITE_ORGANIZATION_FAILED,
            payload: errorResponse,
            error: true
          });
        })
      );
    })
  );

const resendInvitationOrganizationEpic = action$ =>
  action$.pipe(
    ofType(actions.RESEND_INVITE_ORGANIZATION),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'POST',
        `${process.env.REACT_APP_API_URL}/organization/${
          action.payload.id
        }/resend-invitation`
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            if (action.payload.callback) {
              action.payload.callback(false, data.response);
            }
            return organizationActions.resendInvitationFailed(
              data.response.errors
            );
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: `Invitation sent.`
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return organizationActions.resendInvitationSuccess(data.response);
        }),
        catchError(errors => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(errors);
          PubSub.publish('SHOW_NOTIFICATION', {
            msg:
              errorResponse && errorResponse.message
                ? errorResponse.message
                : 'Something went wrong. Please try again later',
            level: 'error'
          });
          if (action.payload.callback) {
            action.payload.callback(false, errorResponse);
          }
          return of({
            type: actions.RESEND_INVITE_ORGANIZATION_FAILED,
            payload: errorResponse,
            error: true
          });
        })
      );
    })
  );

const updateOrganizationEpic = action$ =>
  action$.pipe(
    ofType(actions.UPDATE_ORGANIZATION),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'PUT',
        `${process.env.REACT_APP_API_URL}/organization/${action.payload.id}`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            if (action.payload.callback) {
              action.payload.callback(false, data.response);
            }
            return organizationActions.updateOrganizationFailed(
              data.response.errors
            );
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: `Changes saved.`
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return organizationActions.updateOrganizationSuccess(data.response);
        }),
        catchError(errors => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(errors);
          if (errorResponse) {
            PubSub.publish('SHOW_NOTIFICATION', {
              msg: errorResponse.message
                ? errorResponse.message
                : 'Something went wrong. Please try again later',
              level: 'error'
            });
          }

          if (action.payload.callback) {
            action.payload.callback(false, errorResponse);
          }
          return of({
            type: actions.UPDATE_ORGANIZATION_FAILED,
            payload: errorResponse,
            error: true
          });
        })
      );
    })
  );

const suspendDomainEpic = action$ =>
  action$.pipe(
    ofType(actions.SUSPEND_DOMAIN),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'PUT',
        `${process.env.REACT_APP_API_URL}/organizations/${
          action.payload.id
        }/status`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            if (action.payload.callback) {
              action.payload.callback(false, data.response);
            }
            return organizationActions.suspendDomainFailed(
              data.response.errors
            );
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: `Suspended.`
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return organizationActions.suspendDomainSuccess(data.response);
        }),
        catchError(errors => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(errors);
          if (errorResponse) {
            PubSub.publish('SHOW_NOTIFICATION', {
              msg: errorResponse.message
                ? errorResponse.message
                : 'Something went wrong. Please try again later',
              level: 'error'
            });
          }

          if (action.payload.callback) {
            action.payload.callback(false, errorResponse);
          }
          return organizationActions.suspendDomainFailed(errorResponse);
        })
      );
    })
  );

const restoreSuspendedDomainEpic = action$ =>
  action$.pipe(
    ofType(actions.RESTORE_SUSPENDED_DOMAIN),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'PUT',
        `${process.env.REACT_APP_API_URL}/organizations/${
          action.payload.id
        }/status`,
        action.payload.params
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            if (action.payload.callback) {
              action.payload.callback(false, data.response);
            }
            return organizationActions.restoreSuspendedDomainFailed(
              data.response.errors
            );
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: `Restored.`
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return organizationActions.restoreSuspendedDomainSuccess(
            data.response
          );
        }),
        catchError(errors => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(errors);
          if (errorResponse) {
            PubSub.publish('SHOW_NOTIFICATION', {
              msg: errorResponse.message
                ? errorResponse.message
                : 'Something went wrong. Please try again later',
              level: 'error'
            });
          }

          if (action.payload.callback) {
            action.payload.callback(false, errorResponse);
          }
          return organizationActions.restoreSuspendedDomainFailed(
            errorResponse
          );
        })
      );
    })
  );

const deletePendingDomainEpic = action$ =>
  action$.pipe(
    ofType(actions.DELETE_PENDING_DOMAIN),
    mergeMap(action => {
      PubSub.publish('SHOW_LOADING');
      return makeAjaxRequest(
        'DELETE',
        `${process.env.REACT_APP_API_URL}/organizations/${action.payload.id}`
      ).pipe(
        map(data => {
          PubSub.publish('HIDE_LOADING');
          if (data.response.errors) {
            if (action.payload.callback) {
              action.payload.callback(false, data.response);
            }
            return organizationActions.deletePendingDomainFailed(
              data.response.errors
            );
          }
          PubSub.publish('SHOW_NOTIFICATION', {
            msg: `Deleted.`
          });
          if (action.payload.callback) {
            action.payload.callback(true, data.response);
          }
          return organizationActions.deletePendingDomainSuccess(data.response);
        }),
        catchError(errors => {
          PubSub.publish('HIDE_LOADING');
          const errorResponse = handleErrorsFromServer(errors);
          if (errorResponse) {
            PubSub.publish('SHOW_NOTIFICATION', {
              msg: errorResponse.message
                ? errorResponse.message
                : 'Something went wrong. Please try again later',
              level: 'error'
            });
          }

          if (action.payload.callback) {
            action.payload.callback(false, errorResponse);
          }
          return organizationActions.deletePendingDomainFailed(errorResponse);
        })
      );
    })
  );

export default [
  getOrganizationListEpic,
  inviteOrganizationEpic,
  resendInvitationOrganizationEpic,
  updateOrganizationEpic,
  suspendDomainEpic,
  restoreSuspendedDomainEpic,
  deletePendingDomainEpic
];
