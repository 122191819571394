import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { connect } from 'react-redux';

// this is used to create scrollbars on windows devices like the ones from apple devices
// import PerfectScrollbar from "perfect-scrollbar";
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// backgroundImage for Sidebar

class Sidebar extends Component {
  static propTypes = {
    routes: PropTypes.any,
    location: PropTypes.object,
    currentUser: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute('/components') !== '' ? true : false,
      openForms: this.activeRoute('/forms') !== '' ? true : false,
      openTables: this.activeRoute('/tables') !== '' ? true : false,
      openMaps: this.activeRoute('/maps') !== '' ? true : false,
      openPages: this.activeRoute('/pages') !== '' ? true : false,
      isWindows: navigator.platform.indexOf('Win') > -1 ? true : false,
      width: window.innerWidth
    };
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname === routeName ? 'active' : '';
  };
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }
  componentDidUpdate() {
    if (navigator.platform.indexOf('Win') > -1) {
      setTimeout(() => {}, 350);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
    }
  }

  checkPermission = route => {
    const { currentUser } = this.props;
    if (route && route.roles) {
      if (currentUser && currentUser.roles) {
        const role = filter(currentUser.roles, item => item.group === 'system');
        if (role && role.length) {
          return route.roles.includes(role[0].roleName);
        }
        return false;
      }
      return false;
    }
    return true;
  };

  render() {
    const { routes } = this.props;
    return (
      <div className="sidebar" data-color="black">
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <ul className="nav">
            {map(routes, (prop, key) => {
              if (prop.menu) {
                var st = {};
                st[prop['state']] = !this.state[prop.state];
                if (prop.collapse) {
                  return (
                    <li className={this.activeRoute(prop.path)} key={key}>
                      <a onClick={() => this.setState(st)} href>
                        <i className={prop.menu.icon} />
                        <p>
                          {prop.name}
                          <b
                            className={
                              this.state[prop.state]
                                ? 'caret rotate-180'
                                : 'caret'
                            }
                          />
                        </p>
                      </a>
                      <Collapse in={this.state[prop.state]}>
                        <ul className="nav">
                          {prop.views.map((prop, key) => {
                            return (
                              <li
                                className={this.activeRoute(prop.path)}
                                key={key}
                              >
                                <NavLink
                                  to={prop.path}
                                  className="nav-link"
                                  activeClassName="active"
                                >
                                  <span className="sidebar-mini">
                                    {prop.mini}
                                  </span>
                                  <span className="sidebar-normal">
                                    {prop.name}
                                  </span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  );
                } else {
                  if (prop.redirect) {
                    return null;
                  } else {
                    if (this.checkPermission(prop)) {
                      return (
                        <li className={this.activeRoute(prop.path)} key={key}>
                          <NavLink
                            to={prop.path}
                            className="nav-link"
                            activeClassName="active"
                          >
                            <i className={prop.menu.icon} />
                            <p>{prop.menu.name}</p>
                          </NavLink>
                        </li>
                      );
                    }
                    return null;
                  }
                }
              }
            })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
