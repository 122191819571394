import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class MapCard extends Component {
  render() {
    return (
      <div className="card card-map">
        <div className="header">
          <h4 className="title">{this.props.title}</h4>
        </div>
        <div className="content">{this.props.content}</div>
      </div>
    );
  }
}
MapCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

export default MapCard;
