import Loadable from 'react-loadable';
import { Indicators } from 'components';

const OrganizationList = Loadable({
  loader: () => import('./containers/OrganizationList'),
  loading: Indicators
});

export default [
  {
    path: '/organizations',
    component: OrganizationList,
    private: true,
    roles: ['Tabula Admin', 'Tabula Super Admin', 'Tabula Manager'],
    menu: {
      name: 'Organizations',
      order: 3,
      icon: 'pe-7s-network'
    }
  }
];
