export const actions = {
  GET_ORGANIZATION_LIST: 'GET_ORGANIZATION_LIST',
  GET_ORGANIZATION_LIST_SUCCESS: 'GET_ORGANIZATION_LIST_SUCCESS',
  GET_ORGANIZATION_LIST_FAILED: 'GET_ORGANIZATION_LIST_FAILED',
  INVITE_ORGANIZATION: 'INVITE_ORGANIZATION',
  INVITE_ORGANIZATION_SUCCESS: 'INVITE_ORGANIZATION_SUCCESS',
  INVITE_ORGANIZATION_FAILED: 'INVITE_ORGANIZATION_FAILED',
  RESEND_INVITE_ORGANIZATION: 'RESEND_INVITE_ORGANIZATION',
  RESEND_INVITE_ORGANIZATION_SUCCESS: 'RESEND_INVITE_ORGANIZATION_SUCCESS',
  RESEND_INVITE_ORGANIZATION_FAILED: 'RESEND_INVITE_ORGANIZATION_FAILED',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
  UPDATE_ORGANIZATION_SUCCESS: 'UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_FAILED: 'UPDATE_ORGANIZATION_FAILED',
  SUSPEND_DOMAIN: 'SUSPEND_DOMAIN',
  SUSPEND_DOMAIN_SUCCESS: 'SUSPEND_DOMAIN_SUCCESS',
  SUSPEND_DOMAIN_FAILED: 'SUSPEND_DOMAIN_FAILED',
  RESTORE_SUSPENDED_DOMAIN: 'RESTORE_SUSPENDED_DOMAIN',
  RESTORE_SUSPENDED_DOMAIN_SUCCESS: 'RESTORE_SUSPENDED_DOMAIN_SUCCESS',
  RESTORE_SUSPENDED_DOMAIN_FAILED: 'RESTORE_SUSPENDED_DOMAIN_FAILED',
  DELETE_PENDING_DOMAIN: 'DELETE_PENDING_DOMAIN',
  DELETE_PENDING_DOMAIN_SUCCESS: 'DELETE_PENDING_DOMAIN_SUCCESS',
  DELETE_PENDING_DOMAIN_FAILED: 'DELETE_PENDING_DOMAIN_FAILED'
};
