import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import style from './style';
import PubSub from '../../utils/pubSubService';

import './Notifications.scss';

class Notifications extends Component {
  componentDidMount() {
    const notificationSystem = this.refs.notificationSystem;
    PubSub.subscribe('SHOW_NOTIFICATION', (msg, data) => {
      let defaultMsg = 'Success';
      if (data) {
        switch (data.level) {
          case 'warning':
            defaultMsg = 'Warning';
            break;
          case 'error':
            defaultMsg = 'Error';
            break;
          case 'info':
            defaultMsg = 'Info';
            break;

          default:
            break;
        }
      }

      notificationSystem.addNotification({
        message: data ? data.msg : defaultMsg,
        level: data && data.level ? data.level : 'success',
        position: 'tr',
        autoDismiss: 5
      });
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe('SHOW_NOTIFICATION');
  }

  render() {
    return <NotificationSystem ref="notificationSystem" style={style} />;
  }
}

export default Notifications;
