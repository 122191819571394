import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import Forbidden from '../../modules/Error/Forbidden';
import SideBar from '../../components/SideBar/SideBar';
import Header from '../../components/Header/Header';
import { Card, Indicators, Notifications } from 'components';
import './AppRoute.scss';
class PrivateRoute extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    routerRoles: PropTypes.array,
    routes: PropTypes.array,
    location: PropTypes.object,
    history: PropTypes.object,
    component: PropTypes.any,
    Avatar: PropTypes.any,
    dispatch: PropTypes.func
  };

  state = {
    collapsed: true,
    accessToken: localStorage.getItem('access_token')
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const { accessToken } = this.state;
    if (accessToken) {
      dispatch({
        type: 'GET_CURRENT_USER'
      });
    }
  }

  checkPermission = () => {
    const { currentUser, routerRoles } = this.props;
    if (routerRoles) {
      if (currentUser && currentUser.roles) {
        const role = filter(currentUser.roles, item => item.group === 'system');
        if (role && role.length) {
          return routerRoles.includes(role[0].roleName);
        }
      }
      return false;
    }
    return true;
  };

  renderContent = props => {
    const hasPermission = this.checkPermission();
    const { component: Component } = this.props;
    return hasPermission ? <Component {...props} /> : <Forbidden />;
  };

  render() {
    const {
      component: Component,
      currentUser,
      isAuthenticated,
      routerRoles,
      routes,
      // t,
      ...rest
    } = this.props;
    const { accessToken } = this.state;
    return (
      <Route
        {...rest}
        // t={t}
        render={props =>
          accessToken ? (
            <div id="wrapper" className="wrapper">
              <Indicators />
              <Notifications />
              <SideBar
                routes={this.props.routes}
                location={this.props.location}
                history={this.props.history}
                currentUser={currentUser}
              />
              <div id="page-content-wrapper" className={'main-panel'}>
                <Header
                  avatar={this.props.Avatar}
                  routes={this.props.routes}
                  location={this.props.location}
                  history={this.props.history}
                  currentUser={currentUser}
                />

                <div className="main-content">
                  <div
                    className="container-fluid"
                    style={{ minHeight: 'calc(100vh - 136px)' }}
                  >
                    <Card
                      style={{ minHeight: 'calc(100vh - 150px)' }}
                      content={this.renderContent(props)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.AuthReducer.currentUser
});

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PrivateRoute);
