import React from 'react';

const CustomCheckbox = locals => {
  const hasError = locals.hasError === true ? 'has-danger' : '';
  const msg = locals.error ? (
    <small className="text-danger">{locals.error}</small>
  ) : (
    ''
  );
  const checkboxName = locals.attrs.name || 'checkbox';
  return (
    <div className={`form-group form-group-depth-1 ${hasError}`}>
      <div className="checkbox">
        <input
          checked={locals.value}
          name="checkbox"
          id={checkboxName}
          type="checkbox"
          onChange={event => locals.onChange(event.target.checked)}
        />
        <label htmlFor={checkboxName}>
          {locals.label}{' '}
          {!locals.typeInfo.isMaybe && <span className="text-danger"> *</span>}
        </label>
      </div>
      {msg}
    </div>
  );
};
export default CustomCheckbox;
