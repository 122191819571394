import { actions } from './constants';

export const requestLogin = (identity, password, rememberMe, callback) => ({
  type: actions.REQUEST_LOGIN,
  payload: {
    identity,
    password,
    rememberMe,
    callback
  }
});

export const loginSuccess = payload => ({
  type: actions.LOGIN_SUCCESS,
  payload
  // _message: { title: 'Title message', content: 'Login success' }
});
export const loginReject = error => ({
  type: actions.LOGIN_REJECT,
  payload: {
    error
  }
});

export const requestSetUpAccount = (token, password, callback) => {
  return {
    type: actions.REQUEST_SET_UP_ACCOUNT,
    payload: {
      token,
      password,
      callback
    }
  };
};

export const setUpAccountSuccess = email => ({
  type: actions.SET_UP_ACCOUNT_SUCCESS,
  payload: {
    email
  }
  // _message: { title: 'Title message', content: 'Login success' }
});
export const setUpAccountReject = error => ({
  type: actions.SET_UP_ACCOUNT_REJECT,
  payload: {
    error
  }
});

export const logout = () => ({
  type: actions.LOGOUT
});

export const getCurrentUser = () => ({
  type: actions.GET_CURRENT_USER
});

export const getCurrentUserSuccess = payload => ({
  type: actions.GET_CURRENT_USER_SUCCESS,
  payload
});

export const getCurrentUserFailed = error => ({
  type: actions.GET_CURRENT_USER_FAILED,
  payload: {
    error
  }
});

export const checkToken = (token, type) => ({
  type: actions.CHECK_TOKEN,
  payload: {
    token,
    type
  }
});

export const checkTokenSuccess = payload => ({
  type: actions.CHECK_TOKEN_SUCCESS,
  payload
});

export const checkTokenFailed = payload => ({
  type: actions.CHECK_TOKEN_FAILED,
  payload
});

export const requestForgotPassword = payload => ({
  type: actions.REQUEST_FORGOT_PASSWORD,
  payload
});

export const requestForgotPasswordSuccess = payload => ({
  type: actions.REQUEST_FORGOT_PASSWORD_SUCCESS,
  payload
});

export const requestForgotPasswordFailed = payload => ({
  type: actions.REQUEST_FORGOT_PASSWORD_FAILED,
  payload
});

export const setPassword = payload => ({
  type: actions.SET_PASSWORD,
  payload
});

export const setPasswordSuccess = payload => ({
  type: actions.SET_PASSWORD_SUCCESS,
  payload
});

export const setPasswordFailed = payload => ({
  type: actions.SET_PASSWORD_FAILED,
  payload
});

export const requestConfirmEmail = payload => {
  return {
    type: actions.CONFIRM_EMAIL,
    payload
  };
};
