import Loadable from 'react-loadable';
import { Indicators } from 'components';

const NotFound = Loadable({
  loader: () => import('./NotFound'),
  loading: Indicators
});

const Forbidden = Loadable({
  loader: () => import('./Forbidden'),
  loading: Indicators
});

const InvalidLink = Loadable({
  loader: () => import('./InvalidLink'),
  loading: Indicators
});

export default [
  {
    component: NotFound
  },
  {
    component: Forbidden
  },
  {
    component: InvalidLink
  }
];
