import { actions } from './constants';
const initialState = {
  currentUser: null,
  email: null,
  error: null,
  token: null,
  isTokenExpired: null
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_LOGIN:
      return {
        ...state,
        errors: null
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: { ...action.payload },
        errors: null
      };
    case actions.LOGIN_REJECT:
      return {
        ...state,
        isLogging: false,
        token: null,
        isBusy: false
      };
    case actions.LOGOUT:
      return {
        ...state,
        isBusy: true
      };
    case actions.LOGOUT_SUCCESS:
      return {
        ...state,
        isLogging: false,
        token: null,
        isBusy: false,
        currentUser: null
      };
    case actions.REQUEST_SET_UP_ACCOUNT:
      return {
        ...state,
        isLogging: true,
        isBusy: true
      };
    case actions.SET_UP_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLogging: false,
        email: action.payload.email,
        isBusy: false
      };
    case actions.SET_UP_ACCOUNT_REJECT:
      return {
        ...state,
        isLogging: false,
        email: null,
        isBusy: false,
        error: action.payload.error
      };
    case actions.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload
        }
      };
    case actions.GET_CURRENT_USER_FAILED:
      return {
        ...state,
        currentUser: null
      };
    case actions.CHECK_TOKEN:
      return {
        ...state,
        isTokenExpired: null
      };
    case actions.CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        token: {
          ...state.token,
          ...action.payload
        },
        isTokenExpired: false
      };
    case actions.CHECK_TOKEN_FAILED:
      return {
        ...state,
        token: null,
        isTokenExpired: true
      };
    case actions.RESET_PASSWORD:
      return {
        ...state,
        isLogging: true,
        isBusy: true
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLogging: false,
        email: action.payload.email,
        isBusy: false
      };
    case actions.RESET_PASSWORD_FAILED:
      return {
        ...state,
        isLogging: false,
        email: null,
        isBusy: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default AuthReducer;
