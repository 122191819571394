import { actions } from './constants';

export const getOrganizationList = () => ({
  type: actions.GET_ORGANIZATION_LIST
});

export const getOrganizationSuccess = payload => ({
  type: actions.GET_ORGANIZATION_LIST_SUCCESS,
  payload
});

export const getOrganizationFailed = error => ({
  type: actions.GET_ORGANIZATION_LIST_FAILED,
  payload: {
    error
  }
});

export const inviteOrganization = payload => ({
  type: actions.INVITE_ORGANIZATION,
  payload
});

export const inviteOrganizationSuccess = payload => ({
  type: actions.INVITE_ORGANIZATION_SUCCESS,
  payload
});

export const inviteOrganizationFailed = error => ({
  type: actions.INVITE_ORGANIZATION_FAILED,
  payload: {
    error
  }
});

export const resendInvitation = payload => ({
  type: actions.RESEND_INVITE_ORGANIZATION,
  payload
});

export const resendInvitationSuccess = payload => ({
  type: actions.RESEND_INVITE_ORGANIZATION_SUCCESS,
  payload
});

export const resendInvitationFailed = error => ({
  type: actions.RESEND_INVITE_ORGANIZATION_FAILED,
  payload: {
    error
  }
});

export const updateOrganization = payload => ({
  type: actions.UPDATE_ORGANIZATION,
  payload
});

export const updateOrganizationSuccess = payload => ({
  type: actions.UPDATE_ORGANIZATION_SUCCESS,
  payload
});

export const updateOrganizationFailed = error => ({
  type: actions.UPDATE_ORGANIZATION_FAILED,
  payload: {
    error
  }
});

export const suspendDomain = payload => ({
  type: actions.SUSPEND_DOMAIN,
  payload
});

export const suspendDomainSuccess = payload => ({
  type: actions.SUSPEND_DOMAIN_SUCCESS,
  payload
});

export const suspendDomainFailed = error => ({
  type: actions.SUSPEND_DOMAIN_FAILED,
  payload: {
    error
  }
});

export const restoreSuspendedDomain = payload => ({
  type: actions.RESTORE_SUSPENDED_DOMAIN,
  payload
});

export const restoreSuspendedDomainSuccess = payload => ({
  type: actions.RESTORE_SUSPENDED_DOMAIN_SUCCESS,
  payload
});

export const restoreSuspendedDomainFailed = error => ({
  type: actions.RESTORE_SUSPENDED_DOMAIN_FAILED,
  payload: {
    error
  }
});

export const deletePendingDomain = payload => ({
  type: actions.DELETE_PENDING_DOMAIN,
  payload
});

export const deletePendingDomainSuccess = payload => ({
  type: actions.DELETE_PENDING_DOMAIN_SUCCESS,
  payload
});

export const deletePendingDomainFailed = error => ({
  type: actions.DELETE_PENDING_DOMAIN_FAILED,
  payload: {
    error
  }
});
