import { combineReducers } from 'redux';

import AuthReducer from './modules/Auth/reducer';
import UserReducer from './modules/User/reducer';
import OrganizationReducer from './modules/Organization/reducer';

const rootReducer = combineReducers({
  AuthReducer,
  UserReducer,
  OrganizationReducer
});

export default rootReducer;
