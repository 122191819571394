import Loadable from 'react-loadable';
import { Indicators } from 'components';

const Home = Loadable({
  loader: () => import('./containers/HomePage'),
  loading: Indicators
});

export default [
  {
    path: '/',
    component: Home,
    private: true,
    menu: {
      name: 'Home',
      order: 1,
      icon: 'pe-7s-home'
    }
  }
];
