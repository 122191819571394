import React, { Component } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { Provider } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import { I18nextProvider, withI18n } from 'react-i18next';
import i18n from 'i18next';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import { persistStore, createTransform } from 'redux-persist';
import CryptoJS from 'crypto-js';
import t from 'tcomb-form';
import { PropTypes } from 'prop-types';
import { autoRehydrate } from 'redux-persist';
import isEmpty from 'lodash/isEmpty';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/light-bootstrap-dashboard.scss?v=1.1.1';
import './assets/css/pe-icon-7-stroke.css';

import routes from './routes';
import rootEpic from './main-epic';
import rootReducer from './main-reducer';
import PrivateRoute from './shared/AppRoute/PrivateRoute';
import PublicRoute from './shared/AppRoute/PublicRoute';
import * as resources from './locales';

import './App.scss';
// import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

moment.updateLocale('en', { monthsShort: moment.localeData().months });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware();
const mainStore = createStore(
  rootReducer,
  composeEnhancers(autoRehydrate(), applyMiddleware(epicMiddleware))
);
epicMiddleware.run(rootEpic);
const history = createBrowserHistory();

i18n.init({
  resources: resources,
  // defaultNS: 'common',
  lng: 'en',
  keySeparator: false,
  escapeValue: false
});

t.String.getValidationErrorMessage = (value, path, context) => {
  let message = '';
  if (isEmpty(value)) {
    message = 'This information is required.';
  }
  return message;
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }
  componentWillMount() {
    const encryptTransform = createTransform(
      inboundState => {
        const encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(inboundState),
          'l9KLXyqpt2YEQHtV'
        );
        return encrypted.toString();
      },
      outboundState => {
        const decrypted = CryptoJS.AES.decrypt(
          outboundState,
          'l9KLXyqpt2YEQHtV'
        );
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      }
    );

    persistStore(
      mainStore,
      {
        transforms: [encryptTransform],
        whitelist: ['AuthReducer']
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  }

  render() {
    const { t } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return null;
    return (
      <Provider store={mainStore} t={t}>
        <I18nextProvider i18n={i18n}>
          <Router history={history}>
            <Switch>
              {routes.map((route, index) =>
                route.private === true ? (
                  <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={true}
                    routes={routes}
                    component={route.component}
                    routerRoles={route.roles}
                    t={t}
                  />
                ) : (
                  <PublicRoute
                    component={route.component}
                    path={route.path}
                    exact={true}
                    routes={route.routes}
                    isPublic={route.public}
                    external={route.external}
                    key={index}
                    t={t}
                  />
                )
              )}
            </Switch>
          </Router>
        </I18nextProvider>
      </Provider>
    );
  }
}
App.propTypes = {
  t: PropTypes.func
};
export default withI18n()(App);
